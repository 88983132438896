.BottomButtons {
    display: grid;
    grid-template-columns: auto 1fr auto auto;

    margin: 30px 0;
    border-top: 1px solid #f0f4f7;
    padding-top: 30px;

    button {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
}
