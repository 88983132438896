.hide {
    display: none;
}

.overlay {
    background: #454343c7;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.tutorial {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;
    position: absolute;
    color: #fff;

    &:focus {
        outline: none;
    }

    > div {
        text-align: center;

        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }
}



