.Button {
    background: var(--gray-color);
    border: solid 1px var(--gray-color);
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    cursor: pointer;

    span {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }
}

.Button:hover {
    background: var(--white-color);
    border: solid 1px var(--primary-color);
}

.Button:focus {
    outline: none;
}

.Button:active {
    border: solid 1px var(--light-blue-color);
}

.Button:disabled {
    background: var(--disabled-color);
    border: solid 1px var(--disabled-color);
    color: var(--disabled-color);
    cursor: not-allowed;
}

.error {
    background: rgba(240, 87, 108, 1);
}

.success {
    background: rgba(161, 240, 69, 1);
}

.primary {
    background: var(--primary-color);
    color: var(--white-color);

    span {
        i:before {
            color: #fff;
        }
    }
}

.primary:hover {
    background: var(--primary-hover);
    text-decoration: none;
    color: var(--white-color);
}

.second {
    background: var(--light-gray-color);
    color: var(--primary-color);
}

.second:hover {
    i:before {
        color: var(--primary-color);
    }
}

.third {
    border: none;
    background: none;
    color: var(--dark-gray-color);
    padding: 0;
    display: inline-block;
}

.third:hover {
    border: none;
    color: var(--black-color);
    background: none;
}

.third:active {
    background: none;
    color: var(--dark-gray-color);
    border: none;
}

.third:disabled {
    background: none;
    color: var(--gray-color);
    cursor: not-allowed;
}

.danger {
    background: var(--red-color);
    color: var(--white-color);
}

.danger:hover {
    background: var(--red-color);
    text-decoration: none;
    i:before {
        color: var(--white-color);
    }
}

.warning {
    background: var(--red-color);
    color: var(--white-color);
}

.warning:hover {
    background: var(--red-color);
    text-decoration: none;
    i:before {
        color: var(--white-color);
    }
}

:global(.dark) {
    .primary {
        background: #333538;
        border: #333538;
        color: #ccc;
    }

    .primary:hover {
        background: #44474c;
    }
}
