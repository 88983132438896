.grid {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    li {
        border: solid 1px var(--gray-color);
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            border: solid 1px var(--primary-color);
        }
    }

    .selected {
        background: var(--green-alpha-color);
        border: solid 1px var(--green-color);
    }

    .card {
        display: block;
        margin: 10px;
        text-align: center;

        img {
            width: 100%;
            height: auto;
        }

        .qr {
            width: 276px;
        }
    }
}

.download {
    margin: 0;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%);

    button {
        margin-left: 10px;
    }

    > div {
        background: #ccc;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 4px 6px 9px -3px rgba(0,0,0,0.42);
    }
}

.hide {
    display: none;
}
