.empty {
    margin: 30px 0;
    background: var(--gray-bg-color);
    padding: 15px;
    border: solid 1px var(--light-gray-color);
    border-radius: 4px;
}

.video {
    background: var(--gray-color);
    padding: 10px;
    border-radius: 4px;
    position: relative;

    .hide {
        z-index: 1;
        width: 100%;
        height: 100%;
        background: var(--light-gray-color);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
    }

    .play {
        z-index: 2;
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        color: var(--primary-color);
        cursor: pointer;
    }

    &:hover {
        .play {
            display: block;
        }
    }
}

.instruction {
    width: 100%;
    height: auto;
}

.text {
    margin-bottom: 15px;
}

.center {
    text-align: center;
}

.list {
    list-style: none;
    padding: 0;
}

.place {
    background: var(--gray-bg-color);
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 4px;

    strong {
        display: block;
    }

    &:hover {
        background: var(--green-alpha-color);
    }
}



