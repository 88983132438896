.Submenu {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        a {
            text-decoration: none;
        }
    }
}
