.review {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    margin-bottom: 20px;
}

.icon {
    background: var( --primary-color);
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    color: var( --light-gray-color);
    align-self: end;
}

.message {
    background: var(--blue-color);
    padding: 10px;

    label {
        display: block;
        font-size: 13px;
        color: var(--black-color);
    }

    &:first-child {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    &:last-child {
        border-bottom-right-radius: 15px;
    }
}

.info {
    font-size: 13px;
}

.empty {
    padding: 20px 18px;
    color: var(--black-color);
    background: var(--light-gray-color);
    box-shadow: 0 0 0 1px var(--light-gray-color);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}
