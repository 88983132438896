.actions {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    text-align: center;

    li {
        background: var(--light-gray-color);
        border: solid 1px var(--gray-color);
        color: var(--primary-color);
        border-radius: 4px;
        padding: 50px 30px;
        cursor: pointer;

        &:hover {
            background: var(--white-color);
            border: solid 1px var(--primary-color);
        }
    }

    svg {
        width: 50px;
        height: 50px;
        fill: var(--primary-color);
    }

    a {
        text-decoration: none;
        font-weight: bold;
        color: var(--primary-color);
    }
}
