body {
  --primary-color: #5075ed;
  --primary-hover: #4869d3;
  --accent-color: #f9c529;
  --disabled-color: #ccc;
  --gray-bg-color: #F5F5F5;
  --brown-color: #404B4C;
  --gray-color: #B2B8CB;
  --dark-gray-color: #7986AF;
  --light-gray-color: #f0f4f7;
  --white-color: #ffffff;
  --blue-color: #e2e6f5;
  --light-blue-color: #A3B9F5;
  --black-color: #536493;
  --red-color: #F8571B;
  --green-color: #198d42;
  --green-alpha-color: rgba(25, 141, 66, 0.42);
}

@font-face {
  font-family: 'PTRootUIWebRegular';
  src:
          url('/public/assets/fonts/PT-Root-UI_Regular.woff2') format('woff2'),
          url('/public/assets/fonts/PT-Root-UI_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'PTRootUIWebRegular', sans-serif;
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
