.Table {
  width: 100%;
  margin-bottom: 15px;

  .header th {
    font-size: 12px;
    color: var(--gray-color);
    font-weight: normal;
    padding-left: 18px;
    padding-bottom: 8px;
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .tbody {
    box-shadow: 0 0 0 1px var(--light-gray-color);
    border-radius: 4px;


    tr:nth-child(even) {
      background: var(--white-color);
    }

    tr:nth-child(odd) {
      background: var(--light-gray-color);
    }

    tr {
      font-size: 14px;
      font-weight: 500;
    }

    td {
      padding: 20px 18px;
      color: var(--black-color);
    }
  }

  .dateColumn {
    width: 160px;
  }

  .subtext {
    font-size: 12px;
  }
}

.logo {
  display: inline-block;
  background: #eee;
  border-radius: 100px;
  width: 170px;
  height: 170px;
  position: relative;
  margin: 20px;

  img {
    position: absolute;
    top: -15px;
    left: -10px;
  }
}

.emptyTitle {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.emptyDescription {
  font-size: 14px;
  line-height: 18px;
}

.checkBoxColumn {
  width: 30px;
}

@media only screen and (max-width : 500px) {
  .scroll {
    overflow: auto;
  }
}
