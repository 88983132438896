.Textarea {
    margin-bottom: 15px;
    font-weight: 500;
}

.Textarea.form-field {
    margin-bottom: 16px;
}

.Textarea label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    color: var(--gray-color);
}

.Textarea textarea {
    border: 1px solid var(--gray-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 14px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
    color: var(--black-color);
    max-width: 100%;
    font-size: 15px;

    &::placeholder {
        color: var(--gray-color);
    }
}

.Textarea span {
    color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
}

.Textarea.invalid label {
    color: var(--red-color);
}

.Textarea.invalid textarea {
    border: 1px solid var(--red-color);
}

.Textarea textarea:focus {
    border: 1px solid var(--primary-color);
}

.Textarea textarea:hover {
    border: 1px solid var(--primary-color);
}

.Textarea textarea:active {
    border: 1px solid var(--primary-color);
}
