.menu {
    background: var(--brown-color);
    min-height: 100vh;

    display: grid;
    grid-template-rows: 1fr auto;

    color: var(--white-color);

    a {
        color: var(--white-color);
        text-decoration: none;
    }
}

.nav {
    margin: 15px;
}

.user {
    margin: 15px;
    border-top: solid 1px #ccc;
}
