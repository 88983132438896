.info {
    width: 100px;
}

.tableTitle {
    font-size: 12px;
}

.tableName {
    font-size: 15px;
    font-weight: bold;
    color: var(--black-color);
    padding: 5px 0 10px 0;
}

.tableAction {
    display: flex;
    align-items: center;
}

.panel {
    padding-top: 0px !important;
}
