.form {
    margin: 20px auto;
    max-width: 1200px;

    .body {
        margin: 0 auto;
        max-width: 730px;
    }
}


