.Checkbox label {
}

.Checkbox.invalid label {
    color: var(--red-color);
}

.Checkbox.invalid label a {
    color: var(--red-color);
}

.ColorCheckbox + span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #bcbcbc;
    border-radius: 2px;
    cursor: pointer;
}

.ColorCheckbox:checked + span:after {
    font-family: 'icomoon' !important;
    position: relative;
    top: -5px;
    left: -1px;
    content: '\e96f';
    font-size: 14px;
}

.ColorCheckbox  {
    display: none;
}
