.select {
    margin-bottom: 15px;

    > div {
        height: 38px;
    }
}

.select label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    color: var(--gray-color);
}

:export {
    blackColor: var(--black-color);
    blueColor: var(--primary-color);
    primaryColor:  var(--primary-color);
    lightBlueColor: var(--light-blue-color);
    whiteColor: var(--white-color);
    fontSize: 15px;
    lineHeight: 18px;
    borderColor: var(--gray-color);
    padding: 0 0 0 6px;
    errorColor: var(--red-color);
    disabledColor: var(--disabled-color);
}

.select span {
    color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
}

.small {
    display: inline-block;
    margin: 0 0 0 24px;

    label {
        font-weight: normal;
        display: inline-block;
        margin-right: 8px;
        font-size: 12px;
        color: var(--gray-color);
    }

    select {
        border: none;
        font-size: 12px;
        font-weight: bold;
        color: var(--black-color);
    }
}

:global(.rselect__indicators .rselect__indicator) {
    padding: 6.9px
}

:global(.rselect__menu) {
    z-index: 100;
}

.selectWithAdd {
    display: grid;
    grid-template-columns: 1fr 35px;
    gap: 10px;

    .addButton {
        height: 35px;
        width: 35px;
        margin: 14px 0 0 0;
        background: none;
        border: solid 1px var(--light-gray-color);
        border-radius: 4px;

        i {
            position: relative;
            top: 2px;

            &:before {
                color: var(--gray-color);
            }
        }

        &:hover {
            border-color: var(--blue-color);

            i:before {
                color: var(--dark-gray-color);
            }
        }
    }
}

