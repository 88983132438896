.page {
    .list {
        margin: 0px auto;
        max-width: 1200px;
        padding: 0 30px;
    }

    .header {
        background: #F6F6F9;

        .list {
            display: grid;
            grid-template-rows: 30px 60px 40px 25px;
            padding: 0 30px;
        }
    }

    .body {
        border-radius: 4px;

        .form {
            margin: 0 auto;
            max-width: 730px;
        }
    }
}


