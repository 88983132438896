.TwoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    row-gap: 0px;

    h2 {
        margin: 15px 0 5px 0;
        font-size: 1.4em;
    }
}
