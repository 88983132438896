.dashboard {
    display: grid;
    grid-template-columns: 250px 1fr 0px;
}

.header {
    > div {
        margin: 0px auto;
        max-width: 1200px;
        padding: 15px 30px;
        display: grid;
        grid-template-columns: auto 1fr auto;
    }
}

.menu {
    font-size: 20px;
    margin-right: 15px;
    color: var(--black-color);
}

.logo {
    font-size: 33px;
    font-family: 'PTRootUIWebRegular', sans-serif;
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
}

